<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-23 16:00:31
 * @LastEditTime: 2021-05-26 22:24:59
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <!-- 组织结构 -->
  <div class="framework">
    <SonTitle title="同汇架构"></SonTitle>
    <div class="tabber">
      <ul>
        <li
          :class="{ active: selected == item.id }"
          v-for="item in info"
          :key="item.id"
          @click="changeTab(item)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div v-html="html"></div>
  </div>
</template>

<script>
export default {
  computed: {
    info () {
      return this.$store.state.IndexData.thjiagou || {} // 取出数据
    }
  },
  data () {
    return {
      selected: '', //选中的tab
      html: ''
    }
  },
  created () {
    const { id, content } = this.info[0];
    this.selected = id;
    this.html = content;
  },
  methods: {
    changeTab (item) {
      const { id, content } = item;
      this.selected = id;
      this.html = content;
    }
  },
}
</script>

<style scoped lang='scss'>
.tabber {
  margin-bottom: 30px;
  ul {
    border-bottom: 1px solid #bfbfbf;
    li {
      display: inline-block;
      font-size: 15px;
      color: #2b2b2b;
      font-weight: 500;
      padding: 4px 30px 30px 30px;
      cursor: pointer;
      position: relative;
      &.active {
        color: #b7a16f;
        &::after {
          content: "";
          height: 2px;
          width: 100%;
          background: #b7a16f;
          position: absolute;
          left: 0;
          bottom: -1px;
        }
      }
    }
  }
}
</style>